<template>
    <aside class="aside-container">
        <!-- START Sidebar (left)-->
        <div class="aside-inner">
            <nav class="sidebar" data-sidebar-anyclick-close="">
                <!-- START sidebar nav-->
                <ul class="sidebar-nav">
                    <!-- START user info-->
                    <li class="has-user-block" v-if="$store.state.user">
                        <b-collapse :visible="showUserBlock" id="user-block">
                            <div class="item user-block">
                                <!-- User picture-->
                                <div class="user-block-picture">
                                    <div class="user-block-status">
                                        <!--img class="rank" :src="'img/turnover_ranks/rang_affiliate-' + $store.state.user.rank + '.svg'"/-->
                                        <img class="w-100" :src="$store.state.user.avatar_url" alt="Avatar">
                                        <AvatarMask class="avatar-mask"/>
                                        <div class="circle bg-info circle-lg"></div>
                                    </div>
                                </div>
                                <div class="user-block-info">
                                    <span class="user-block-name">{{$t('common.layout.sidebar.greeting', {username: $store.state.user.name})}}</span>
                                </div>
                                <div class="user-block-links mt-2">
                                    <router-link tag="a" :to="{name: 'Settings'}" active-class="active" class="btn mr-1 mb-1 btn-outline-info">
                                        <i class="fad fa-cog mr-1"></i><span>{{$t('common.layout.sidebar.settings')}}</span>
                                    </router-link>
                                </div>
                            </div>
                        </b-collapse>
                    </li>
                    <!-- END user info-->
                    <!-- Iterates over all sidebar items-->
                    <template v-for="item in items">
                        <!-- Heading -->
                        <li class="nav-heading" v-if="item.heading && !item.hidden">
                            <span>{{$t(item.translate) || item.heading}}</span>
                        </li>
                        <!-- Single Menu -->
                        <router-link tag="li" :to="item.path" active-class="active" v-if="!item.heading && !item.submenu && !item.comingSoon && !item.hidden">
                            <a :title="tr(item.translate, item.name)">
                                <span v-if="item.label" :class="'float-right badge badge-'+item.label.color">{{item.label.value}}</span>
                                <em :class="item.icon"></em>
                                <span>{{tr(item.translate, item.name)}}</span>
                            </a>
                        </router-link>
                        <li v-if="!item.heading && !item.submenu && item.comingSoon && !item.hidden">
                            <a>
                                <span v-if="item.label" :class="'float-right badge badge-'+item.label.color">{{item.label.value}}</span>
                                <em :class="item.icon"></em>
                                <span>{{tr(item.translate, item.name)}}</span>
                            </a>
                        </li>
                    </template>
                </ul>
                <!-- END sidebar nav-->
            </nav>
        </div>
        <!-- END Sidebar (left)-->
    </aside>
</template>

<script>

    import {mapState} from 'vuex';
    import SidebarRun from './Sidebar.run.js';
    import Menu from '../../menu.js';
    import AvatarMask from '@/../public/img/hexagon-mask.svg?inline';

    export default {
        name: 'Sidebar',
        components: {
            AvatarMask
        },
        data() {
            return {
                items: Menu.items(),
                collapse: []
            }
        },
        mounted() {
            this.collapse = this.buildCollapseList();
            SidebarRun(this.$router, this.closeSidebar.bind(this))
        },
        computed: {
            ...mapState({
                showUserBlock: state => state.setting.showUserBlock
            })
        },
        watch:{
            $route (to, from){
                this.closeSidebar()
            }
        },
        methods: {
            closeSidebar() {
                this.$store.commit('changeSetting', { name: 'asideToggled', value: false })
            },
            buildCollapseList() {
                /** prepare initial state of collapse menus. Doesnt allow same route names */
                let collapse = {};
                this.items
                    .filter(({heading}) => !heading)
                    .forEach(({name, path, submenu}) => {
                        collapse[name] = this.isRouteActive(submenu ? submenu.map(({path})=>path) : path)
                    })
                return collapse;
            },
            getSubRoutes(item) {
                return item.submenu.map(({path}) => path)
            },
            // translate a key or return default values
            tr (key, defaultValue) {
                return key ? this.$t(key, {defaultValue: defaultValue}) : defaultValue;
            },
            isRouteActive(paths) {
                paths = Array.isArray(paths) ? paths : [paths];
                return paths.some(p => this.$route.path.indexOf(p) > -1)
            },
            routeActiveClass(paths) {
                return { 'active': this.isRouteActive(paths) }
            },
            toggleItemCollapse(collapseName) {
                for (let c in this.collapse) {
                    if (this.collapse[c] === true && c !== collapseName)
                        this.collapse[c] = false
                }
                this.collapse[collapseName] = !this.collapse[collapseName]
            }

        }
    }
</script>