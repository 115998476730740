import Layout from "../components/Layout/LayoutContent";
import LayoutWallCenter from "../components/Layout/LayoutWallCenter";

export default class ModuleBase {

    constructor(options) {
        this.routes = options.routes || null;
        this.components = options.components || null;
        this.name = options.name || null;
        this.config = options.config || null;
    }

    loadRoutes(router) {
        if (this.routes && this.routes.requiresAuth) {
            router.addRoute({
                path: '/',
                component: Layout,
                meta: {requiresAuth: true},
                children: this.tagRoutesWithModuleName(this.routes.requiresAuth)
            });
        }

        if (this.routes && this.routes.public) {
            router.addRoute({
                path: '/',
                component: LayoutWallCenter,
                children: this.tagRoutesWithModuleName(this.routes.public)
            });
        }
    }

    tagRoutesWithModuleName(routes) {
        routes.forEach(item => {

            item.meta = item.meta || {};
            item.meta.modulesRequired = item.meta.modulesRequired || [];
            item.meta.modulesRequired.push(this.name);

            if (item.children) {
                this.tagRoutesWithModuleName(item.children);
            }
        })

        return routes;
    }

    loadComponents(Vue) {
        if (this.components) {
            this.components.forEach(comp => {
                Vue.component(comp[0], comp[1])
            })
        }
    }

    getName() {
        return this.name;
    }

}