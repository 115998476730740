//Wallet
const Posts = () => import ('./../views/Posts.vue');
const Post = () => import ('./../views/Post.vue');

export default {
    requiresAuth: [
        {
            path: 'posts',
            name: 'News:Posts',
            meta: {
                requiresAuth: true
            },
            component: Posts
        },
        {
            path: 'post/:id/:vanity_url?',
            name: 'News:Post',
            meta: {
                requiresAuth: true
            },
            component: Post
        }
    ]
};