<template>
  <i18n :path="'common.number_format.' + this.type" tag="span" class="text-nowrap">
    <template v-slot:amount>
      <template v-if="animated !== undefined">
        <span><animated-number :value="amount" :formatValue="$options.filters[type]" :duration="200"/></span>
      </template>
      <template v-if="animated === undefined">{{ $options.filters[type](amount) }}</template>
    </template>
  </i18n>
</template>

<script>

import AnimatedNumber from "animated-number-vue";

export default {
  props: [
    'amount',
    'type'
  ],
  data() {
    return {
      animated: true
    }
  },
  components: {
    AnimatedNumber
  },
}
</script>
