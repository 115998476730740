//modules
import CommonModule from './modules/Common/common.module';
import MarketingModule from './modules/Marketing/marketing.module';
import WalletModule from './modules/Wallet/wallet.module';
import GrowingModule from './modules/Growing/growing.module';
import ChatModule from './modules/Chat/chat.module';
import AffiliateModule from './modules/Affiliate/affiliate.module';
import SupportModule from './modules/Support/support.module';
import ShopModule from './modules/Shop/shop.module';
import AppointmentModule from './modules/Appointment/appointment.module';
import PaymentModule from './modules/Payment/payment.module';
import NewsModule from './modules/News/news.module';


//bootstrap modules
import ModuleLoader from './classes/ModuleLoader';

ModuleLoader.load(CommonModule)
ModuleLoader.load(WalletModule)
ModuleLoader.load(MarketingModule)
ModuleLoader.load(GrowingModule)
ModuleLoader.load(ChatModule)
ModuleLoader.load(AffiliateModule)
ModuleLoader.load(SupportModule)
ModuleLoader.load(ShopModule)
ModuleLoader.load(AppointmentModule)
ModuleLoader.load(PaymentModule)
ModuleLoader.load(NewsModule)

export default ModuleLoader;


