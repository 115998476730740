import store from '../store/index.js';
import router from "../router.js";
import api from './api.service';
import fcm from './fcm.service';
import Swal from 'sweetalert2';
import {helpers} from '../i18n/index';

class AuthService {

    login(data) {

        if (data.user && data.user.language) {
            helpers.changeLocale(data.user.language);
        }

        this.silentLogin(data);
        this.redirectToDashboard();
    }

    silentLogin(data) {
        store.commit('loginUser', data);
        api.setToken(data.token);
        if (window._paq) {
            window._paq.push(['setUserId', data.user.id + '-' + data.user.name]);
        }
    }

    lock() {
        store.commit('lockSession');
        api.clearToken();
        Swal.close();

        if (!store.state.loginMail) {
            router.push({name: 'Login'});
        }

        router.push({name: 'Lock'});
    }

    unlock(data) {
        this.silentLogin(data);
        if (router.currentRoute.query.target === 'shop') {
            this.redirectToShop();
        } else {
            this.redirectToDashboard();
        }
    }

    hasPermissionTo(key) {
        return !(store.state.user.permissions.indexOf(key) === -1);
    }

    redirectToDashboard() {
        if (store.state.redirectAfterLogin && store.state.redirectAfterLogin !== '/lock') {
            router.push(store.state.redirectAfterLogin);
            store.commit('redirectAfterLogin', false);
        } else {
            router.push({name: 'Dashboard'});
        }
    }

    logout() {
        this.quickLogout();
        api.post('auth/logout');
        if (window._paq) {
            window._paq.push(['resetUserId']);
        }
        router.push({name: 'Login'});
    }

    quickLogout() {
        api.clearToken();
        store.commit('logoutUser');
    }

    sendVerificationCode() {
        return api.post('user/request-verification-code')
    }

    requestVerfificationCode(type = '') {
        if (store.state.user) {
            if (store.getters.user('google2fa_enabled_at') && type !== 'email') {
                return Swal.fire({
                    title: 'Two Factor Authentication',
                    input: 'text',
                    customClass: 'verification-code-modal',
                    inputPlaceholder: 'Enter your one time password here'
                }).then(response => {
                    return {verification_code: response.value};
                })
            } else if (store.getters.user('email_verified') && type !== '2fa') {
                return Swal.fire({
                    title: 'We sent you a verification code',
                    input: 'text',
                    customClass: 'verification-code-modal',
                    inputValue: this.sendVerificationCode().then(() => {
                        return ''
                    }),
                    inputPlaceholder: 'Enter verification code here'
                }).then(response => {
                    return {verification_code: response.value};
                })
            } else {
                return Promise.resolve({
                    verification_code: ''
                });
            }
        }
    }

    showErrorMessage(error) {
        if (error.status === 422) {
            let errorKey = Object.keys(error.data.errors)[0];
            Swal.fire(error.data.errors[errorKey][0], '', 'error');
        }
    }
}

export default new AuthService();