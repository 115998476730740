// index.js
import store from "../store/index";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import moment from 'moment';

Vue.use(VueI18n);

export const languages = {
    en: 'available',
    de: 'available'
};

//get user settings -> client -> old client fallback
let navigator_language = store.state.language || navigator.language || navigator.userLanguage;

if (navigator_language) {
    //shorten to 2 characters if available
    navigator_language = navigator_language.substring(0, 2);
}

if (navigator_language === 'ch') {
    navigator_language = 'de';
}

if (!navigator_language || !languages[navigator_language]) {
    //set default if no locale found
    navigator_language = 'en'
}

export const clientLocale = navigator_language;

export const i18n = new VueI18n({
    locale: clientLocale,
    fallbackLocale: 'en',
    debug: process.env.VUE_ENV !== 'production',
    interpolation: {
        prefix: '{',
        suffix: '}'
    },
    silentTranslationWarn: true,
    missing: function (locale, path, vue) {

    }
});

export const helpers = {
    changeLocale: function(locale) {
        i18n.locale = locale;
        moment.locale(locale);
        return locale;
    }
}

helpers.changeLocale(clientLocale);

export async function loadNamespace(modulesRequired, language = null) {

    modulesRequired.push('Common');

    language = language || i18n.locale;

    let namespacesToLoad = [];

    modulesRequired.forEach(moduleName => {
        if (!i18n.te(moduleName, language)) {
            namespacesToLoad.push(moduleName);
        }
    });

    if (namespacesToLoad.length > 0) {
        let promises = [];
        namespacesToLoad.forEach(namespace => {
            if (namespace !== 'Common') {

                promises.push(import('../modules/' + namespace + '/locales/' + namespace.toLowerCase() + '.' + language + '.json').then(messages => {
                    i18n.mergeLocaleMessage(language, messages);
                }));
                promises.push(import('../modules/' + namespace + '/locales/' + namespace.toLowerCase() + '.' + i18n.fallbackLocale + '.json').then(messages => {
                    i18n.mergeLocaleMessage(i18n.fallbackLocale, messages);
                }));

            } else {
                promises.push(import('../modules/Common/locales/common.' + language + '.json').then(messages => {
                    i18n.mergeLocaleMessage(language, messages);
                }));
                promises.push(import('../modules/Common/locales/common.' + i18n.fallbackLocale + '.json').then(messages => {
                    i18n.mergeLocaleMessage(i18n.fallbackLocale, messages);
                }));
            }
        });

        return Promise.all(promises);
    } else {
        return Promise.resolve();
    }
}