//Wallet
const Wallet = () => import ('./../views/Wallet.vue');

const ActionMakeWithdraw = () => import ('./../views/Actions/MakeWithdraw.vue');
const ActionMakeDeposit = () => import ('./../views/Actions/MakeDeposit.vue');

const Transaction = () => import ('./../views/Transaction.vue');
const Withdraw = () => import ('./../views/Withdraw.vue');
const Order = () => import ('./../views/Order.vue');
const OrderBankDetails = () => import ('./../views/OrderBankDetails.vue');
const Summary = () => import ('./../views/Summary.vue');

export default {
    requiresAuth: [
        {
            path: 'financial',
            name: 'Wallet',
            component: Wallet
        },

        //actions
        {
            path: 'financial/action/make-withdraw',
            name: 'Wallet:ActionMakeWithdraw',
            component: ActionMakeWithdraw
        },
        {
            path: 'financial/action/make-deposit',
            name: 'Wallet:ActionMakeDeposit',
            component: ActionMakeDeposit,
            meta: {
                modulesRequired: ['Wallet', 'Growing']
            }
        },

        //items
        {
            path: 'financial/order/:order_label/bank-details',
            name: 'Wallet:OrderBankDetails',
            component: OrderBankDetails
        },
        {
            path: 'financial/order/:order_label/:status?',
            name: 'Wallet:Order',
            component: Order
        },
        {
            path: 'financial/transaction/:transaction_label',
            name: 'Wallet:Transaction',
            component: Transaction
        },
        {
            path: 'financial/withdraw/:withdraw_label',
            name: 'Wallet:Withdraw',
            component: Withdraw
        },
        {
            path: 'financial/summary',
            name: 'Wallet:Summary',
            component: Summary
        },
    ]
};