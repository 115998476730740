import routesGrowing from './routes/growing.routes';
import routesSale from './routes/sale.routes';
import routesStorage from './routes/storage.routes';
import ModuleBase from '../../classes/ModuleBase';

export default new ModuleBase({
    name: 'Growing',
    routes: {
        requiresAuth:
            routesGrowing.requiresAuth
                .concat(
                    routesSale.requiresAuth,
                    routesStorage.requiresAuth
                )
    },
    config: {
        theme: {
            menu: [
                {
                    name: 'Growing:Sale',
                    path: '/sale',
                    icon: 'cai-grow',
                    translate: 'common.layout.sidebar.nav.sale'
                }
            ]
        }
    }
});