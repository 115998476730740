import Vue from 'vue'
import Router from '../router';

class ModuleLoader {

    constructor() {
        this.configValues = {};
        this.modules = [];
    }

    load(module) {
        module.loadRoutes(Router);
        module.loadComponents(Vue);

        if (module.config) {
            this.configValues = this.mergeDeep(this.configValues, module.config);
        }

        this.modules.push(module.getName());

        return this;
    }

    getModuleNames() {
        return this.modules;
    }

    getNameFromNamespace(namespace) {
        let name = '';
        this.getModuleNames().forEach(moduleName => {
            if (moduleName.toLowerCase() === namespace) {
                name = moduleName;
            }
        })
        return name;
    }

    config(key, defaultValue) {

        let keySplitter = key.split(':');

        let moduleName = keySplitter[0];
        let configPath = keySplitter[1];

        let configPathSplitter = configPath.split('.');

        if (this.configValues.hasOwnProperty(moduleName)) {
            let currentValue = this.configValues[moduleName]

            for (let configKey in configPathSplitter) {

                if (typeof currentValue === 'object' && currentValue.hasOwnProperty(configPathSplitter[configKey])) {
                    currentValue = currentValue[configPathSplitter[configKey]];
                } else {
                    return defaultValue || undefined;
                }
            }

            return currentValue;
        }

        return defaultValue || undefined;
    }

    mergeDeep(...objects) {
        const isObject = obj => obj && typeof obj === 'object';

        return objects.reduce((prev, obj) => {
            Object.keys(obj).forEach(key => {
                const pVal = prev[key];
                const oVal = obj[key];

                if (Array.isArray(pVal) && Array.isArray(oVal)) {
                    prev[key] = pVal.concat(...oVal);
                } else if (isObject(pVal) && isObject(oVal)) {
                    prev[key] = this.mergeDeep(pVal, oVal);
                } else {
                    prev[key] = oVal;
                }
            });

            return prev;
        }, {});
    }
}

export default new ModuleLoader();