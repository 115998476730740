import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import Vue from 'vue'

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        Vue,
        dsn: "https://3b31768858034f56ba27bc77f1d71fe0@sentry.infra.code.gt/11",
        release: process.env.PACKAGE_VERSION,
        environment: process.env.NODE_ENV + (process.env.CORDOVA_PLATFORM ? '_' + process.env.CORDOVA_PLATFORM : ''),
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            }),
        ],
        ignoreErrors: [
            'chunk chunk',
            'Network Error',
            'Request aborted',
            'timeout of 0ms exceeded',
            'Navigation cancelled',
            'NavigationDuplicated',
            'Error: [object XMLHttpRequest]'
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

import VueAnalytics from 'vue-analytics';
import VueMatomo from 'vue-matomo';
import BootstrapVue from 'bootstrap-vue';
import VueClipboard from 'vue-clipboard2'
import moment from 'moment';
require('moment/locale/de');
import VeeValidate from 'vee-validate';

//global components
import BreadCrumb from './components/Common/Breadcrumb.vue';
import FormattedNumber from './components/Common/FormattedNumber';

import './vendor'
import './filters';

import App from './App.vue';
import router from './router';
import store from './store/index';
import ApiService from './services/api.service';
import AuthService from './services/auth.service';
import TitleService from './services/title.service';
import FcmService from './services/fcm.service';

import swal from 'sweetalert2';
import { i18n } from './i18n/index';

import modules from './modules';

Vue.use(VeeValidate, {
    fieldsBagName: 'formFields'
});

Vue.use(VueClipboard);
Vue.use(VeeValidate);
Vue.use(require('vue-moment'), {
    moment
})
Vue.use(BootstrapVue);

Vue.config.productionTip = false;

if (process.env.NODE_ENV === 'production') {
    Vue.use(VueAnalytics, {
        id: process.env.VUE_APP_GOOGLE_ANALYTICS,
        router,
    });

    Vue.use(VueMatomo, {
        router: router,
        host: 'https://' + process.env.VUE_APP_MATOMO_DOMAIN,
        siteId: process.env.VUE_APP_MATOMO_SITE_ID,
        trackerFileName: 'm',
        trackerUrl: 'https://' + process.env.VUE_APP_MATOMO_DOMAIN + '/m',
        trackerScriptUrl: 'https://' + process.env.VUE_APP_MATOMO_DOMAIN + '/m',
        userId: store.state.user ? store.getters.user('id') + '-' + store.getters.user('name') : undefined,
    });
}

Vue.component('breadcrumb', BreadCrumb);
Vue.component('formatted-number', FormattedNumber);

Vue.prototype.$setErrorsFromResponse = function (errorResponse, form) {

    // only allow this function to be run if the validator exists
    if (!this.hasOwnProperty('$validator')) {
        return;
    }

    // clear errors
    this.$validator.errors.clear();

    // check if errors exist
    if (!errorResponse.hasOwnProperty('errors')) {
        return;
    }

    let errorFields = Object.keys(errorResponse.errors);

    // insert laravel errors
    errorFields.map(field => {
        let errorString = errorResponse.errors[field].join(', ');
        this.$validator.errors.add({
            field: form + '.' + field,
            msg: errorString
        });
    });
};

//static infos
Vue.prototype.$isApp = (!!process.env.CORDOVA_PLATFORM || process.env.VUE_APP_FORCE_ANDROID === '1');

//services
Vue.prototype.$api = ApiService;
Vue.prototype.$fcm = FcmService;
Vue.prototype.$auth = AuthService;
Vue.prototype.$title = TitleService;
Vue.prototype.$modules = modules;

//popup
Vue.prototype.$swal = swal;

const app = new Vue({
    i18n,
    router,
    modules,
    render: h => h(App),
}).$mount('#app');