//Marketing
let Marketing = () => import ('./../views/Marketing.vue');
let MarketingLogo = () => import ('./../views/MarketingLogo.vue');

export default {
    requiresAuth: [
        {
            path: 'marketing',
            name: 'Marketing',
            redirect: {name: 'Marketing:Logo'},
            meta: {requiresAuth: true},
            component: Marketing,
            children: [
                {
                    path: 'logo',
                    name: 'Marketing:Logo',
                    meta: {requiresAuth: true},
                    component: MarketingLogo
                }
            ]
        },
    ]
};