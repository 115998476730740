//Growing
const Plants = () => import ('../views/Plants.vue');
const PlantBrowser = () => import ('../views/Plants/PlantBrowser.vue');
const Harvests = () => import ('../views/Harvests.vue');
const Grow = () => import ('../views/Grow.vue');
const Plant = () => import ('../views/Plant.vue');
const Grows = () => import ('../views/Grows.vue');
const HarvestDistribution = () => import ('../views/HarvestDistribution.vue');
const Market = () => import ('../views/Market.vue');
const MarketTerms = () => import ('../views/Market/TermsAndConditions.vue');

export default {
    requiresAuth: [
        {
            path: 'harvests',
            name: 'Growing:Harvests',
            meta: {requiresAuth: true},
            component: Harvests
        },
        {
            path: 'harvest/:harvest_label',
            name: 'Growing:HarvestDistribution',
            meta: {requiresAuth: true},
            component: HarvestDistribution
        },
        {
            path: 'grow/:label',
            name: 'Growing:Grow',
            meta: {requiresAuth: true},
            component: Grow
        },
        {
            path: 'plants',
            name: 'Growing:Plants',
            meta: {requiresAuth: true},
            component: Plants,
            children: [
                {
                    path: 'browser',
                    name: 'Growing:PlantBrowser',
                    meta: {requiresAuth: true},
                    component: PlantBrowser
                }
            ]
        },
        {
            path: 'plant/:label',
            name: 'Growing:Plant',
            meta: {requiresAuth: true},
            component: Plant
        },
        {
            path: 'grows',
            name: 'Growing:Grows',
            meta: {requiresAuth: true},
            component: Grows
        },
        {
            path: 'market',
            name: 'Growing:Market',
            meta: {requiresAuth: true},
            component: Market
        },
        {
            path: 'market/terms',
            name: 'Growing:MarketTerms',
            meta: {requiresAuth: true},
            component: MarketTerms
        }
    ]
};

