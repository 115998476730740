const ChatConversations = () => import ('../views/Conversations');
const ChatConversation = () => import ('../views/Messages');

export default {
    requiresAuth: [
        {
            path: 'chat',
            name: 'Chat:Conversations',
            meta: {requiresAuth: true},
            component: ChatConversations,
            children: [
                {
                    path: 'conversation/:conversationId',
                    name: 'Chat:Conversation',
                    meta: {requiresAuth: true},
                    component: ChatConversation,
                }
            ]
        },
    ]
};