import axios from "axios";
import router from "../router.js";
import store from "../store/index";
import Pusher from "pusher-js";
import Swal from 'sweetalert2';
import Echo from "laravel-echo";

class ApiService {

    constructor() {

        this.token = null;
        this.broadcaster = null;

        this.swalActive = false;

        this.proxyOptions = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/vnd.example.v1+json',
            },
           // withCredentials: true
        };

        axios.defaults.baseURL = this.hostUrl('api');

        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            (error) => {
                if (error.response) {
                    if (error.response.status === 401 && router.currentRoute.name !== 'Login' && router.currentRoute.name !== 'Lock' && error.response.data.message === 'Unauthenticated.') {

                        if (store.state.isLoggedIn) {
                            store.commit('redirectAfterLogin', router.currentRoute.path);
                        }

                        this.lock();
                    } else if (error.response.status === 500) {
                        Swal.fire(error.response.statusText, 'We reported this error to our development team. Please try again later.', 'warning');
                    } else if (error.response.status === 503) {
                        Swal.fire('We are under Maintenance', 'Please try again later, we\'ll be back soon', 'error');
                    } else if (error.response.status === 429 && !this.swalActive) {
                        this.swalActive = true;
                        Swal.fire('Too many requests', 'Please try again later', 'error').then(() => {
                            this.swalActive = false;
                        });
                    }

                    return Promise.reject(error.response);
                } else {
                    return Promise.reject(error);
                }
            });

        if (store.state.token) {
            this.setToken(store.state.token);
        }

      /*  if (document.cookie.indexOf('XSRF-TOKEN=') === -1) {
            this.get('csrf-cookie');
        }*/

        this.checkTokenStatus();
        setInterval(() => {
            this.checkTokenStatus();
        }, 1000);
    }

    hostUrl(suffix) {
        return (process.env.VUE_APP_API_HTTPS === '1' ? 'https://' : 'http://') + process.env.VUE_APP_API_HOST + '/' + suffix;
    }

    generatePusherKey() {
        let hostBuffer = Buffer.from(process.env.VUE_APP_API_HOST);
        let base64Host = hostBuffer.toString('base64');
        return base64Host.replace(/[=+\\/]/gi, '');
    }

    lock() {
        store.commit('lockSession');
        this.clearToken();
        Swal.close();

        if (router) {
            if (!store.state.loginMail) {
                router.push({name: 'Login'});
            }

            router.push({name: 'Lock'});
        }
    }

    checkTokenStatus() {
        if (store.state.token) {
            let current_time = new Date().getTime();
            let token_expire_time = store.state.token.session_start + ((store.state.token.expires_in) * 1000);

            if (token_expire_time <= current_time) {
                this.clearToken();
                this.lock()
            }
        }
    }

    get(url, params) {
        params = params || {};
        return axios.get(url, Object.assign({params: params}, this.proxyOptions));
    }

    delete(url, params) {
        return axios.delete(url, this.proxyOptions);
    }

    update(url, params) {
        return axios.put(url, params, this.proxyOptions);
    }

    post(url, params) {
        return axios.post(url, params, this.proxyOptions);
    }

    refreshToken() {
        if (store.state.isLoggedIn) {
            this.post('auth/refresh').then(response => {
                store.commit('loginUser', response.data);
            })
        }
    }


    downloadPDF(url, fileName) {
        return this.get(url).then(response => {
            var fileURL = window.URL.createObjectURL(new Blob([this.b64toBlob(response.data)]));;
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', fileName + '.pdf');
            document.body.appendChild(fileLink);
            fileLink.click();

            return fileURL;
        });
    }

    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, {type: contentType});
    }

    messageListener(callback) {
        if (this.broadcaster) {
            return this.broadcaster.private('App.Models.User.' + store.state.user.id).listen('\\Modules\\Chat\\Events\\NewMessageReceived', event => {
                callback(event.message);
            });
        } else {
            return null;
        }
    }

    setToken(token) {
        this.token = token;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token.access_token;
    }

    checkBroadcaster() {
        if (!this.broadcaster && this.token) {

            this.broadcaster = new Echo({
                authEndpoint: this.hostUrl('broadcasting/auth'),
                broadcaster: 'pusher',
                key: this.generatePusherKey(),
                wsHost: process.env.VUE_APP_API_HOST,
                wsPort: 443,
                forceTLS: false,
                disableStats: true,
                enabledTransports: [process.env.VUE_ENV !== 'production' ? 'ws' : 'wss'],
                auth: {
                    headers: {
                        'Authorization': 'Bearer ' + this.token.access_token
                    }
                },
                cluster: 'eu'
            });

            //disconnect to prevent reconnection loop
            this.broadcaster.connector.pusher.connection.bind('unavailable', () => {
                this.broadcaster.connector.pusher.disconnect();
            });

        } else {
            //   this.broadcaster.connector.pusher.options.auth.headers['Authorization'] = 'Bearer ' + token.access_token;
        }
    }

    toggleInstructionState(stateName) {
        store.commit('toggleInstructionState', stateName);
        return this.post('instructions/toggle-state/' + stateName);
    }

    clearToken() {
        this.token = null;
        axios.defaults.headers.common['Authorization'] = '';
        if (this.broadcaster) {
            this.broadcaster.disconnect();
        }
        this.broadcaster = null;
    }

    showErrorMessage(error) {
        if (error.status === 422) {
            if (error.data.error) {
                Swal.fire(error.data.error, '', 'error');
            } else {
                let errorKey = Object.keys(error.data.errors)[0];
                Swal.fire(error.data.errors[errorKey][0], '', 'error');
            }
        }
    }

}

export default new ApiService();