//Marketing
import Layout from "../../../components/Layout/LayoutContent";

let Payment = () => import ('./../views/MakePayment.vue');

export default {
    requiresAuth: [
        {
            path: 'payment/:ref',
            name: 'Payment',
            meta: {requiresAuth: true, modulesRequired: ['Wallet']},
            component: Payment
        },
    ]
};