//Sale
const BuyPlant = () => import ('./../views/BuyPlant.vue');
const Participation = () => import ('./../views/Participation.vue');
const ParticipationFaq = () => import ('./../views/Participation/ParticipationFaq.vue');
const ParticipationPrediction = () => import ('./../views/Participation/ParticipationPrediction.vue');
const ParticipationTickets = () => import ('./../views/Participation/ParticipationTickets.vue');

export default {
    requiresAuth: [
        {
            path: 'swap',
            name: 'Growing:Participation',
            meta: {
                requiresAuth: true,
                modulesRequired: ['Wallet', 'Growing']
            },
            component: Participation,
            children: [
                {
                    path: 'faq',
                    name: 'Growing:ParticipationFaq',
                    meta: {requiresAuth: true,
                        modulesRequired: ['Wallet', 'Growing']},
                    component: ParticipationFaq
                },
                {
                    path: 'prediction',
                    name: 'Growing:ParticipationPrediction',
                    meta: {requiresAuth: true,
                        modulesRequired: ['Wallet', 'Growing']},
                    component: ParticipationPrediction
                }
            ]
        },
        {
            path: 'sale/tickets',
            name: 'Growing:Tickets',
            meta: {requiresAuth: true,
                modulesRequired: ['Wallet', 'Growing']},
            component: ParticipationTickets
        }
    ]
};