<template>
    <router-view/>
</template>

<script>
    import store from './store/index.js'

    export default {
        name: 'App',
        store,
        watch: {
            '$route' (to, from) {
              //  document.title = to.meta.title || 'CannerGrow'
            }
        }
    }
</script>

<style lang="scss">
  // Import Main styles for this application
  @import '@/assets/styles/bootstrap.scss';
  @import '@/assets/styles/app.scss';
</style>
