import router from "../router.js";
import api from './api.service';

class FCMService {

    constructor() {
        this.platform = process.env.CORDOVA_PLATFORM
        this.isApp = (!!process.env.CORDOVA_PLATFORM || process.env.VUE_APP_FORCE_ANDROID === '1')

        //request permissions
        if (window.Notification && Notification.permission !== "denied") {
            Notification.requestPermission();
        }
    }

    ready() {
        //notification events for routing on tapping
        /*  FCMPlugin.onNotification(function(data){
              if(data.wasTapped){
                  //Notification was received on device tray and tapped by the user.
                  alert( JSON.stringify(data) );
              }else{
                  //Notification was received in foreground. Maybe the user needs to be notified.
                  alert( JSON.stringify(data) );
              }
          });*/
    }

    registerDevice() {
        if (this.platform === 'android') {
            cordova.plugins.firebase.messaging.getToken().then(function(token) {
                api.post('fcm/register', {
                    token: token
                });
            });
        }
    }

    isNewNotificationSupported() {
        if (!window.Notification || !Notification.requestPermission)
            return false;
        if (Notification.permission == 'granted')
            throw new Error('You must only call this *before* calling Notification.requestPermission(), otherwise this feature detect would bug the user with an actual notification!');
        try {
            new Notification('');
        } catch (e) {
            if (e.name == 'TypeError')
                return false;
        }
        return true;
    }

    showNotification(title, data) {

        try {
            let n = new Notification(title, data);
            setTimeout(function () {
                n.close();
            }, 8000);
            return true;
        } catch (e) {
            if (e.name == 'TypeError')
                return false;
        }

        /*
        if (this.isNewNotificationSupported()) {
            navigator.serviceWorker.ready.then(function(registration) {
                registration.showNotification(title, data);
            });
        } else if (window.Notification && Notification.permission == 'granted') {
            let n = new Notification(title, data);
            setTimeout(function () {
                n.close();
            }, 8000);
        }*/
    }

}

let service = new FCMService();

document.addEventListener('deviceReady', service.ready, false)

export default service;