import routes from './routes/payment.routes';
import ModuleBase from '../../classes/ModuleBase';

export default new ModuleBase({
    name: 'Payment',
    routes: routes,
    config: {

    },
    components: [

    ]
});