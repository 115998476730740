//Team
const Team = () => import ('../views/Team');
const Leaderboard = () => import ('../views/Leaderboard');

export default {
    requiresAuth: [

        {
            path: 'team',
            name: 'Affiliate:Team',
            meta: {requiresAuth: true},
            component: Team
        },
        {
            path: 'leaderboard',
            name: 'Affiliate:Leaderboard',
            meta: {requiresAuth: true},
            component: Leaderboard
        },
    ]
};