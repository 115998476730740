class TitleService {

    constructor() {

    }

    set(title) {
        document.title = title + ' - ' + process.env.VUE_APP_TITLE_SUFFIX;
    }

}

export default new TitleService();