import routes from './routes/wallet.routes';
import ModuleBase from '../../classes/ModuleBase';

export default new ModuleBase({
    name: 'Wallet',
    routes: routes,
    config: {
        theme: {
            settings: {
                pages: [
                    {
                        route: 'SettingsWithdrawAccounts',
                        icon: ''
                    }
                ]
            },
            menu: [
                {
                    name: 'Wallet',
                    path: '/financial',
                    icon: 'cai-cash',
                    translate: 'common.layout.sidebar.nav.financial'
                }
            ]
        }
    },
    components: [

    ]
});