<template>
    <div class="wrapper layout-wall-center">

        <!-- Page content-->
        <router-view/>

    </div>
</template>
<style lang="scss">

    .abs-center {
        height: 100%;
        padding-top: 3%;
    }

    .background-particles {
        top: 0;
        position: fixed;
        z-index: -1;
        left: 0;
    }
</style>
<script>


    export default {
        data() {
            return {}
        },
        mounted() {

        },
        methods: {}
    }
</script>