<template>
    <div class="breadcrumb-wrapper section-title animated-fast fadeInDown">
        <div><i :class="activeItem.icon" class="mr-3"></i>{{ tr(activeItem.translate, activeItem.name) }}</div>
    </div>
</template>
<script>

    import Menu from '../../menu.js';

    export default {
        data() {
            return {
                activeItem: {
                    name: 'Loading',
                    translate: null
                }
            }
        },
        props: ['name', 'icon'],
        mounted() {
            if (this.name) {
                this.activeItem.name = this.name;
            } else {
                //fallback to route name
                this.activeItem.name = this.$route.name
            }

            Menu.items().forEach(item => {
                if (item.name === this.$route.name) {
                    this.activeItem = item;
                }
            })

            if (this.icon) {
                this.activeItem.icon = this.icon;
            }
        },
        methods: {
            // translate a key or return default values
            tr(key, defaultValue) {
                let name = key ? this.$t(key, {defaultValue: defaultValue}) : defaultValue;
                this.$title.set(name);
                return name;
            },
        }
    }


</script>