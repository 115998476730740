<template>
    <div class="wrapper layout-content" :class="{'is-mobile': isMobile}">

        <!-- top navbar-->
        <Header/>

        <!-- sidebar-->
        <Sidebar/>

        <!-- Notification container -->
        <notifications group="main" :position="'right top'"  :class="'right-top'">

            <template slot="body" slot-scope="props">
                <div class="notification vue-notification" :class="props.item.type">
                    <div class="row align-items-center">
                        <div class="col-2">
                            <div class="notification-icon"><i :class="props.item.data.class"></i></div>
                        </div>
                        <div class="col-10">
                            <div class="notification-title"> {{props.item.title}}</div>
                            <div class="notification-content"> {{props.item.text}}</div>
                        </div>
                    </div>
                </div>
            </template>
        </notifications>

        <!-- Main section-->
        <section class="section-container">
            <!-- Page content-->
            <transition name="content">
                <router-view/>
            </transition>

        </section>

        <!-- Page footer-->
        <Footer/>

    </div>
</template>
<style lang="scss">

    .content-enter-active, .content-leave-active {
        transition-duration: .25s;
    }

    .content-enter-active {
        position: absolute;
        top: 0;
    }

    .content-enter-active {
        transition-delay: .25s;
        .animated-fast {
            animation-delay: .25s;
        }
    }

    .content-leave-active {
        .animated-fast.fadeInDown {
            animation-name: fadeOutUp;
        }
        .animated-fast.fadeInRight {
            animation-name: fadeOutRight;
        }
        .animated-fast.fadeInLeft {
            animation-name: fadeOutLeft;
        }
        .animated-fast.fadeInUp {
            animation-name: fadeOutDown;
        }
    }

</style>
<script>
    import Vue from 'vue';
    import Header from './Header'
    import Sidebar from './Sidebar'
    import Footer from './Footer'
    import ContentWrapper from './ContentWrapper'
    import Notifications from 'vue-notification'

    Vue.use(Notifications);

    Vue.component('ContentWrapper', ContentWrapper);

    export default {
        name: 'Layout',
        data() {
            return {
                duration: 2000,
                isAndroid: false,
            }
        },
        components: {
            Header,
            Sidebar,
            Footer,
        },
        beforeCreate() {
            this.$api.checkBroadcaster();

            this.$api.broadcaster.private('App.Models.User.' + this.$store.state.user.id).notification(notification => {
                this.pushNotification(notification);

                if (notification.event) {
                    if (notification.event.name === 'email-verified') {
                        this.$store.commit('emailVerified');
                    }
                }
            });

            this.$api.messageListener(message => {
                this.pushNotification({
                    title: 'New Message from ' + message.sender.name,
                    description: message.body.substring(0, 10) + (message.body.length > 20 ? '...' : ''),
                    status: 'info',
                    class: 'fad fa-envelope'
                });
            });
        },
        beforeMount() {
            this.isMobile = navigator.userAgent.match(/iPhone|iPad|iPod|Android/i);
        },
        methods: {
            mapType(status) {
                if (status === 'danger') {
                    return 'error'
                }
                return 'success'
            },
            pushNotification(notification) {

                this.$notify({
                    group: 'main',
                    title: notification.title,
                    text: notification.description,
                    type: this.mapType(notification.status),
                    data: {
                        class: notification.class,
                    },
                    closeOnClick: true,
                    duration: 8000
                });

                this.$fcm.showNotification(notification.title, {
                    body: notification.description,
                    icon: '/img/logo/cannerald-notification-192x192.png'
                });
            }
        }
    }
</script>