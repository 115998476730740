//Growing
const Appointments = () => import ('../views/Appointments.vue');
const RegisterTicket = () => import ('../views/RegisterTicket.vue');
const Event = () => import ('../views/Event.vue');
const Meeting = () => import ('../views/Public/Meeting.vue');

export default {
    requiresAuth: [
        {
            path: 'appointments',
            name: 'Appointment:Appointments',
            meta: {
                requiresAuth: true,
                modulesRequired: ['Wallet']
            },
            component: Appointments
        },
        {
            path: 'appointment/register-ticket/:label',
            name: 'Appointment:AppointmentRegisterTicket',
            meta: {requiresAuth: true},
            component: RegisterTicket
        },
        {
            path: 'event',
            name: 'Appointment:Event',
            meta: {
                requiresAuth: true,
                modulesRequired: ['Wallet']
            },
            component: Event
        }
    ],
    public: [
        {
            component: Meeting,
            path: 'meeting/:vanity_url?',
            name: 'Appointment:Meeting'
        }
    ]
};
