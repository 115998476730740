//Growing - StoragemodulesRequired: ['Wallet', 'Growing']
const Storage = () => import ('../views/Storage/Storage.vue');
const Delivery = () => import ('../views/Storage/Delivery.vue');
const SellHarvest = () => import ('../views/Storage/SellHarvest.vue');
const DeliverHarvest = () => import ('../views/Storage/DeliverHarvest.vue');

export default {
    requiresAuth: [
        {
            path: 'storage',
            name: 'Growing:Storage',
            meta: {
                requiresAuth: true,
                modulesRequired: ['Wallet', 'Growing']
            },
            component: Storage
        },
        {
            path: 'storage/delivery/:id',
            name: 'Growing:Storage:Delivery',
            meta: {
                requiresAuth: true,
                modulesRequired: ['Wallet', 'Growing']
            },
            component: Delivery
        },
        {
            path: 'storage/:grow_label/sell',
            name: 'Growing:StorageSellHarvest',
            meta: {requiresAuth: true},
            component: SellHarvest
        },
        {
            path: 'storage/:grow_label/deliver',
            name: 'Growing:StorageDeliverHarvest',
            meta: {requiresAuth: true},
            component: DeliverHarvest
        },
    ]
};