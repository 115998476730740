import store from "@/store/index";

export default {
    items() {
        return [
            {
                heading: 'Main Navigation',
                translate: 'common.layout.sidebar.heading.header'
            },
            {
                name: 'Dashboard',
                path: '/dashboard',
                icon: 'cai-cannerald',
                translate: 'common.layout.sidebar.nav.dashboard'
            },
            {
                hidden: !store.getters.hasPlants,
                name: 'Growing:Participation',
                path: '/swap',
                icon: 'cai-cannerald',
                translate: 'common.layout.sidebar.nav.participation'
            },
            {
                name: 'News:Posts',
                path: '/posts',
                icon: 'cai-solution',
                translate: 'common.layout.sidebar.nav.news'
            },
            {
                name: 'Wallet',
                path: '/financial',
                icon: 'cai-cash',
                translate: 'common.layout.sidebar.nav.financial'
            },
            {
                heading: 'Status',
                translate: 'common.layout.sidebar.heading.status'
            },
            {
                hidden: !store.getters.hasPlants,
                name: 'Growing:PlantBrowser',
                path: '/plants/browser',
                icon: 'cai-plants-own',
                translate: 'common.layout.sidebar.nav.plants'
            },
            {
                name: 'Growing:Market',
                path: '/market',
                icon: 'cai-science',
                translate: 'common.layout.sidebar.nav.market',
            },
            {
                hidden: !store.getters.hasPlants,
                name: 'Growing:Storage',
                path: '/storage',
                icon: 'cai-storage',
                translate: 'common.layout.sidebar.nav.storage'
            },
            {
                hidden: !store.getters.hasFirstline,
                heading: 'Marketing',
                translate: 'common.layout.sidebar.heading.marketing'
            },
            {
                hidden: !store.getters.hasFirstline,
                name: 'Affiliate:Team',
                path: '/team',
                icon: 'cai-user-group',
                translate: 'common.layout.sidebar.nav.team',
            },
            {
                heading: 'Help',
                translate: 'common.layout.sidebar.heading.help'
            },
            {
                name: 'Support:FAQ',
                path: '/faq',
                icon: 'cai-faq',
                translate: 'common.layout.sidebar.nav.faq'
            }
        ]

    }
}