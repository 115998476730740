//Shop
const ShopProducts = () => import ('../views/Products.vue');
const ShopOrders = () => import ('../views/Orders.vue');
const ShopSales = () => import ('../views/Sales.vue');

export default {
    requiresAuth: [
        {
            path: 'shop/orders',
            name: 'Shop:Orders',
            meta: {requiresAuth: true},
            component: ShopOrders
        },
        {
            path: 'shop/products',
            name: 'Shop:Products',
            meta: {requiresAuth: true},
            component: ShopProducts
        },
        {
            path: 'shop/sales',
            name: 'Shop:Sales',
            meta: {requiresAuth: true, modulesRequired: ['Wallet']},
            component: ShopSales
        },
    ]
};